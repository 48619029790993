import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import {
    withAuthenticatedUserContext
} from "../../../context/AuthenticatedUserContext";
import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import numberFormatter from "../../../components/formatters/NumberFormatter";
import IBANFormatter from "../../../components/formatters/IBANFormatter";
import BSNFormatter from "../../../components/formatters/BSNFormatter";
import EuroFormatter from "../../../components/formatters/EuroFormatter";
import initialsFormatter from "../../../components/formatters/InitialsFormatter";
import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";
import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import DetailOverviewComments from "../../../components/detail/DetailOverviewComments";
import AppleMapsModal from "../../../components/apple-maps/AppleMapsModal";
import VATRefundDetailSetStatusModal from "./modal/VATRefundDetailSetStatusModal";
import DownloadApplicationPDFButton from "./components/DownloadApplicationPDFButton";
import ClickCopy from "../../../components/copy/ClickCopy";
import QuarterFormatter from "../components/QuarterFormatter";

class VATRefundDetailOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            comments: null,
            newCommentLoading: false,
            error: null,
            errorComments: null,
            errorNewComment: null,
            errorCoordinates: null,
            qrCodeLoaded: false,

            errorDownload: null,

            showSetStatusModal: false,
            showSetInvoicingStatusModal: false,
            showAppleMapsModal: false
        };
        this.onDownloadError = this.onDownloadError.bind(this);
    }

    componentDidMount() {
        this.getComments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.vatRefundId !== null && this.props.vatRefundId !== prevProps.vatRefundId) {
            this.getProducts();
            this.getComments(true);
        }
    }

    setVATRefundProps(props) {
        this.props.setVATRefund((prevVATRefund) => {
            return { ...prevVATRefund, ...props };
        });
    }

    clearWarnings() {
        this.setVATRefundProps({ warnings: [] });
    }

    getComments(clearComments = false) {
        if(clearComments) {
            this.setState({ comments: null });
        }
        this.setState({ errorComments: null });
        axios.post("/getVATRefundComments", { vatRefundId: this.props.vatRefundId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ comments: response.data.data });
                } else {
                    this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addComment(message, images, clearContent) {
        if(message.trim().length === 0) {
            this.setState({ errorNewComment: "De notitie kan niet leeg zijn." });
            return;
        }
        this.setState({ errorNewComment: null, newCommentLoading: true });
        axios.post("/addVATRefundComment", { vatRefundId: this.props.vatRefundId, message })
            .then(async (response) => {
                if(response.data.valid) {
                    if(!await this.uploadImagesIfNeeded(response.data.comment.id, images)) {
                        this.setState({ errorNewComment: "Er is een fout opgetreden. Niet alle foto's zijn geupload." });
                    }
                    this.getComments();
                    this.clearWarnings();
                    clearContent();
                } else {
                    this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ newCommentLoading: false });
            });
    }

    async uploadImagesIfNeeded(commentId, images) {
        for(const image of images) {
            if(!await this.uploadImage(commentId, image)) {
                return false;
            }
        }
        return true;
    }

    async uploadImage(commentId, image) {
        if(image.file === null) {
            return false;
        }

        const formData = new FormData();
        formData.append("vatRefundCommentId", commentId);
        formData.append("file", image.file, image.file.name);

        let response = await axios.post("/uploadImage", formData, {
            transformRequest: (data, headers) => {
                headers.setContentType(undefined);
                return data;
            }
        });

        return !(!response.data || !response.data.valid);
    }

    onCommentAdded = this.commentAdded.bind(this);
    commentAdded() {
        this.getComments();
    }

    commentUpdated(comment) {
        this.setState((prevState) => {
            const comments = prevState.comments.map((searchComment) => {
                if(searchComment.id === comment.id && searchComment.commentType === comment.commentType) {
                    return comment;
                }
                return searchComment;
            });
            return { comments };
        });
    }

    commentDeleted() {
        this.getComments();
    }

    refreshCoordinates() {
        this.setState({ errorCoordinates: null });
        axios.post("/refreshVATRefundCoordinates", { vatRefundId: this.props.vatRefundId })
            .then((response) => {
                if(response.data.valid) {
                    this.setVATRefundProps({
                        latitude: response.data.data.latitude,
                        longitude: response.data.data.longitude,
                        locationLastUpdate: response.data.data.locationLastUpdate
                    });
                } else {
                    if(response.data.error === "TOO_QUICK") {
                        this.setState({ errorCoordinates: "De locatie van deze BTW Terugvraag is 5 minuten geleden al bijgewerkt. Wacht even met het verversen van de locatie." });
                    } else if(response.data.error === "GOOGLE_ERROR") {
                        if(response.data.google === "ZERO_RESULTS") {
                            this.setState({ errorCoordinates: "Er konden geen coördinaten voor dit adres gevonden worden." });
                        } else {
                            this.setState({ errorCoordinates: "Er ging iets fout bij de aanvraag voor coördinaten bij Google. Probeer het later opnieuw. (" + response.data.google + ")" });
                        }
                    } else {
                        this.setState({ errorCoordinates: "Er ging iets fout bij het verversen van de coördinaten. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorCoordinates: "Er ging iets fout bij het verversen van de coördinaten. Probeer het later opnieuw." });
            });
    }

    onHandleStatusChanged = this.handleStatusChanged.bind(this);
    handleStatusChanged(status) {
        this.handleCloseSetStatusModal();
        this.setVATRefundProps({ status });
        this.clearWarnings();
        this.getComments();
    }

    onHandleInvoicingStatusChanged = this.handleInvoicingStatusChanged.bind(this);
    handleInvoicingStatusChanged(invoicingStatus) {
        this.handleCloseSetInvoicingStatusModal();
        this.setVATRefundProps({ invoicingStatus });
        this.clearWarnings();
        this.getComments();
    }

    onHandleCloseSetStatusModal = this.handleCloseSetStatusModal.bind(this);
    handleCloseSetStatusModal() {
        this.setState({ showSetStatusModal: false });
    }

    onHandleCloseSetInvoicingStatusModal = this.handleCloseSetInvoicingStatusModal.bind(this);
    handleCloseSetInvoicingStatusModal() {
        this.setState({ showSetInvoicingStatusModal: false });
    }

    onDownloadError(error) {
        this.setState({ errorDownload: error });
    }

    getAddress() {
        const vatRefund = this.props.vatRefund;
        return vatRefund.street + " " + vatRefund.houseNumber + ", " + vatRefund.city;
    }

    hasPermission() {
        return this.props.authenticatedUserContext.user.vatRefund;
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            );
        }
        const vatRefund = this.props.vatRefund;
        const permission = this.hasPermission();
        return (
            <React.Fragment>
                { vatRefund && (
                    <React.Fragment>
                        { permission && (
                            <VATRefundDetailSetStatusModal
                                show={ this.state.showSetStatusModal }
                                vatRefund={ vatRefund }
                                handleClose={ this.onHandleCloseSetStatusModal }
                                handleStatusChanged={ this.onHandleStatusChanged }
                                handleCommentAdded={ this.onCommentAdded }
                            />
                        )}
                        <AppleMapsModal
                            show={ this.state.showAppleMapsModal }
                            latitude={ vatRefund.latitude }
                            longitude={ vatRefund.longitude }
                            title={ vatRefund.initials.split("").join(".") + ". " + vatRefund.lastName }
                            emptyState="Er zijn geen coördinaten beschikbaar voor deze BTW Terugvraag."
                            handleClose={ () => this.setState({ showAppleMapsModal: false} ) }
                            refreshCoordinates={ this.refreshCoordinates.bind(this) }
                            refreshCoordinatesError={ this.state.errorCoordinates }
                        />
                    </React.Fragment>
                )}

                { vatRefund && vatRefund.warnings && vatRefund.warnings.map((warning, index) => (
                    <Alert variant="warning" key={ index } className="d-print-none">{ warning }</Alert>
                ))}

                <DetailOverviewQRCode
                    content={ vatRefund ? `vatRefund=${vatRefund.id}` : null }
                    caption="Scan deze code in de Sales app om deze BTW Terugvraag direct te openen op je telefoon."
                />

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Naam">
                                { vatRefund ? (
                                    initialsFormatter({ initials: vatRefund.initials }) + " " + vatRefund.lastName
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Status">
                                { vatRefund ? (
                                    <React.Fragment>
                                        <TagPill color={vatRefund.status.color}>
                                            {vatRefund.status.name}
                                        </TagPill>
                                        { permission && (
                                            <Button variant="link" size="sm" onClick={ () => this.setState({ showSetStatusModal: true }) } className="py-0">
                                                <i className="fas fa-pen"/>
                                            </Button>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={175}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Geboortedatum">
                                { vatRefund ? (
                                    <ClickCopy text={ moment(vatRefund.birthday).format("DD-MM-YYYY") }>
                                        <DateFormatter
                                            date={ vatRefund.birthday }
                                            format="DD-MM-YYYY"
                                        />
                                    </ClickCopy>
                                ) : <Skeleton width={90}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Telefoonnummer">
                                { vatRefund ? (
                                    <a href={"dial:" + vatRefund.phoneNumber}>
                                        {vatRefund.phoneNumber}
                                    </a>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Email">
                                { vatRefund ? (
                                    <a href={"mailto:" + vatRefund.email}>
                                        {vatRefund.email}
                                    </a>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Adres">
                                { vatRefund ? (
                                    <React.Fragment>
                                        <ClickCopy text={ this.getAddress() }>
                                            { this.getAddress() }
                                        </ClickCopy>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Google Maps</Tooltip>
                                        }>
                                            <a href={ "https://google.com/maps?q=" + encodeURIComponent(this.getAddress()) + "&t=k" }
                                               className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-map"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Google Earth</Tooltip>
                                        }>
                                            <a href={ "https://earth.google.com/web/search/" + encodeURIComponent(this.getAddress()) }
                                               className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-globe-europe"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Apple Maps</Tooltip>
                                        }>
                                            <Button onClick={ () => this.setState({ showAppleMapsModal: true }) } variant="link" size="sm" className="py-0">
                                                <i className="fab fa-apple"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Postcode">
                                { vatRefund ? (
                                    <ClickCopy text={ vatRefund.postalCode }>
                                        { vatRefund.postalCode }
                                    </ClickCopy>
                                ) : (
                                    <Skeleton width={60}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Ondernemer">
                                { vatRefund ? (
                                    vatRefund.entrepreneur ? "Ja" : "Nee"
                                ) : <Skeleton width={30}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="BSN">
                                { vatRefund ? (
                                    vatRefund.bsn === undefined ? (
                                        <i className="text-muted">Verborgen</i>
                                    ) : (
                                        <ClickCopy text={ vatRefund.bsn }>
                                            <BSNFormatter bsn={ vatRefund.bsn }/>
                                        </ClickCopy>
                                    )
                                ) : <Skeleton width={90}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="BTW nummer">
                                { vatRefund ? (
                                    vatRefund.vatNumber === undefined ? (
                                        <i className="text-muted">Verborgen</i>
                                    ) : vatRefund.vatNumber === null ? (
                                        <i className="text-muted">Leeg</i>
                                    ) : (
                                        <ClickCopy text={ vatRefund.vatNumber }>
                                            { vatRefund.vatNumber }
                                        </ClickCopy>
                                    )
                                ) : <Skeleton width={90}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="IBAN">
                                { vatRefund ? (
                                    !vatRefund.iban ? (
                                        <i className="text-muted">Verborgen</i>
                                    ) : (
                                        <ClickCopy text={ vatRefund.iban }>
                                            <IBANFormatter iban={ vatRefund.iban }/>
                                        </ClickCopy>
                                    )
                                ) : <Skeleton width={175}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="IBAN naam">
                                { vatRefund ? (
                                    vatRefund.ibanName === undefined ? (
                                        <i className="text-muted">Verborgen</i>
                                    ) : vatRefund.ibanName.length === 0 ? (
                                        <i className="text-muted">Leeg</i>
                                    ) : (
                                        <ClickCopy text={ vatRefund.ibanName }>
                                            { vatRefund.ibanName }
                                        </ClickCopy>
                                    )
                                ) : <Skeleton width={175}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Installatie vermogen">
                                { vatRefund ? (
                                    <ClickCopy text={ vatRefund.wp }>
                                        { numberFormatter({ number: vatRefund.wp }) + " Wp" }
                                    </ClickCopy>
                                ) : <Skeleton width={75}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Kwartaal">
                                { vatRefund ? (
                                    !vatRefund.quarter ? (
                                        <i className="text-muted">Leeg</i>
                                    ) : (
                                        <ClickCopy text={ moment(vatRefund.quarter).format("[Q]Q YYYY") }>
                                            <QuarterFormatter date={ vatRefund.quarter }/>
                                        </ClickCopy>
                                    )
                                ) : <Skeleton width={75}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Factuurdatum">
                                { vatRefund ? (
                                    !vatRefund.invoiceDate ? (
                                        <i className="text-muted">Leeg</i>
                                    ) : (
                                        <ClickCopy text={ moment(vatRefund.invoiceDate).format("DD-MM-YYYY") }>
                                            <DateFormatter
                                                date={ vatRefund.invoiceDate }
                                                format="DD-MM-YYYY"
                                            />
                                        </ClickCopy>
                                    )
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Factuur BTW bedrag">
                                { vatRefund ? (
                                    !vatRefund.invoiceVATPrice ? (
                                        <i className="text-muted">Leeg</i>
                                    ) : (
                                        <ClickCopy text={ vatRefund.invoiceVATPrice }>
                                            <EuroFormatter
                                                price={ vatRefund.invoiceVATPrice }
                                            />
                                        </ClickCopy>
                                    )
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Datum ontvangen">
                                { vatRefund ? (
                                    <DateFormatter
                                        date={ vatRefund.date }
                                    />
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Locatie">
                                { vatRefund ? (
                                    <React.Fragment>
                                        { vatRefund.latitude && vatRefund.longitude ? (
                                            <React.Fragment>
                                                { vatRefund.latitude + ", " + vatRefund.longitude }
                                            </React.Fragment>
                                        ) : (
                                            <i className="text-muted">Geen locatie beschikbaar</i>
                                        )}
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Coördinaten verversen</Tooltip>
                                        }>
                                            <button className="btn btn-link btn-sm ml-2 py-0" onClick={ () => this.refreshCoordinates() }>
                                                <i className="fas fa-sync"/>
                                            </button>
                                        </OverlayTrigger>
                                        { vatRefund.latitude && vatRefund.longitude && (
                                            <React.Fragment>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="address">Coördinaten openen in Google Maps</Tooltip>
                                                }>
                                                    <a href={ "https://google.com/maps?q=" + vatRefund.latitude + "," + vatRefund.longitude + "&t=k" }
                                                       className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                        <i className="fas fa-map"/>
                                                    </a>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="address">Coördinaten openen in Google Earth</Tooltip>
                                                }>
                                                    <a href={ "https://earth.google.com/web/search/" + vatRefund.latitude + "," + vatRefund.longitude }
                                                       className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                        <i className="fas fa-globe-europe"/>
                                                    </a>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="address">Coördinaten openen in Apple Maps</Tooltip>
                                                }>
                                                    <Button onClick={ () => this.setState({ showAppleMapsModal: true }) } variant="link" size="sm" className="py-0">
                                                        <i className="fab fa-apple"/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </React.Fragment>
                                        )}
                                        { this.state.errorCoordinates !== null && (
                                            <Alert variant="danger" className="mb-0">{ this.state.errorCoordinates }</Alert>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow>
                                { this.state.errorDownload !== null && (
                                    <Alert variant="danger">{ this.state.errorDownload }</Alert>
                                )}
                                { vatRefund ? (
                                    <React.Fragment>
                                        <Link to={ `/installation/${vatRefund.installation.id}` } className="btn btn-secondary btn-sm">
                                            Installatie openen
                                        </Link>
                                        { this.hasPermission() && (
                                            <DownloadApplicationPDFButton
                                                vatRefund={ vatRefund }
                                                onError={ this.onDownloadError }
                                            />
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <div className="d-inline-block">
                                        <Skeleton height={30} width={127} className="mr-2"/>
                                        { this.hasPermission() && (
                                            <Skeleton height={30} width={222}/>
                                        )}
                                    </div>
                                )}
                            </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>
                <DetailOverviewComments
                    comments={ this.state.comments }
                    error={ this.state.errorComments }
                    errorNewComment={ this.state.errorNewComment }
                    newCommentLoading={ this.state.newCommentLoading }
                    onSubmit={ this.addComment.bind(this) }
                    commentUpdated={ this.commentUpdated.bind(this) }
                    commentDeleted={ this.commentDeleted.bind(this) }
                    emptyState="Er staan nog geen notities onder deze BTW Terugvraag."
                />
            </React.Fragment>
        )
    }
}

export default withAuthenticatedUserContext(VATRefundDetailOverview);
