import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";

import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import DetailOverviewComments from "../../../components/detail/DetailOverviewComments";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import AppleMapsModal from "../../../components/apple-maps/AppleMapsModal";
import InstallationDetailSetStatusModal from "./modal/InstallationDetailSetStatusModal";
import InstallationDetailSetInvoicingStatusModal from "./modal/InstallationDetailSetInvoicingStatusModal";
import InstallationSendVATRefundToken from "./modal/InstallationSendVATRefundToken";
import ClickCopy from "../../../components/copy/ClickCopy";
import ProductAvailabilityIcon from "./components/ProductAvailabilityIcon";

class InstallationDetailOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            comments: null,
            newCommentLoading: false,
            error: null,
            errorComments: null,
            errorNewComment: null,
            errorCoordinates: null,
            qrCodeLoaded: false,

            showSetStatusModal: false,
            showSetInvoicingStatusModal: false,
            showAppleMapsModal: false,
            showSendVATRefundTokenModal: false,
        };
    }

    componentDidMount() {
        this.getProducts();
        this.getInstallationComments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.installationId !== null && this.props.installationId !== prevProps.installationId) {
            this.getProducts();
            this.getInstallationComments(true);
        }
    }

    setInstallationProps(props) {
        this.props.setInstallation((prevInstallation) => {
            return { ...prevInstallation, ...props };
        });
    }

    clearInstallationWarnings() {
        this.setInstallationProps({ warnings: [] });
    }

    getProducts() {
        this.setState({ products: null, error: null });
        axios.post("/getInstallationProducts", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    let products = response.data.reserved;
                    for(const product of response.data.taken) {
                        let index = products.findIndex((findProduct) => findProduct.id === product.id);
                        if(index === -1) {
                            product.availability = "taken";
                            products.push(product);
                        } else {
                            products[index].installationAmount += product.installationAmount;
                        }
                    }
                    this.setState({ products });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getInstallationComments(clearComments = false) {
        if(clearComments) {
            this.setState({ comments: null });
        }
        this.setState({ errorComments: null });
        axios.post("/getInstallationComments", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ comments: response.data.data });
                } else {
                    this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addComment(message, images, clearContent) {
        if(message.trim().length === 0) {
            this.setState({ errorNewComment: "De notitie kan niet leeg zijn." });
            return;
        }
        this.setState({ errorNewComment: null, newCommentLoading: true });
        axios.post("/addInstallationComment", { installationId: this.props.installationId, message })
            .then(async (response) => {
                if(response.data.valid) {
                    if(!await this.uploadImagesIfNeeded(response.data.comment.id, images)) {
                        this.setState({ errorNewComment: "Er is een fout opgetreden. Niet alle foto's zijn geupload." });
                    }
                    this.getInstallationComments();
                    this.clearInstallationWarnings();
                    clearContent();
                } else {
                    this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ newCommentLoading: false });
            });
    }

    async uploadImagesIfNeeded(commentId, images) {
        for(const image of images) {
            if(!await this.uploadImage(commentId, image)) {
                return false;
            }
        }
        return true;
    }

    async uploadImage(commentId, image) {
        if(image.file === null) {
            return false;
        }

        const formData = new FormData();
        formData.append("installationCommentId", commentId);
        formData.append("file", image.file, image.file.name);

        let response = await axios.post("/uploadImage", formData, {
            transformRequest: (data, headers) => {
                headers.setContentType(undefined);
                return data;
            }
        });

        return !(!response.data || !response.data.valid);
    }

    onCommentAdded = this.commentAdded.bind(this);
    commentAdded() {
        this.getInstallationComments();
    }

    commentUpdated(comment) {
        this.setState((prevState) => {
            const comments = prevState.comments.map((searchComment) => {
                if(searchComment.id === comment.id && searchComment.commentType === comment.commentType) {
                    return comment;
                }
                return searchComment;
            });
            return { comments };
        });
    }

    commentDeleted() {
        this.getInstallationComments(this.props.installationId);
    }

    refreshCoordinates() {
        this.setState({ errorCoordinates: null });
        axios.post("/refreshInstallationCoordinates", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setInstallationProps({
                        latitude: response.data.data.latitude,
                        longitude: response.data.data.longitude,
                        locationLastUpdate: response.data.data.locationLastUpdate
                    });
                } else {
                    if(response.data.error === "TOO_QUICK") {
                        this.setState({ errorCoordinates: "De locatie van deze installatie is 5 minuten geleden al bijgewerkt. Wacht even met het verversen van de locatie." });
                    } else if(response.data.error === "GOOGLE_ERROR") {
                        if(response.data.google === "ZERO_RESULTS") {
                            this.setState({ errorCoordinates: "Er konden geen coördinaten voor dit adres gevonden worden." });
                        } else {
                            this.setState({ errorCoordinates: "Er ging iets fout bij de aanvraag voor coördinaten bij Google. Probeer het later opnieuw. (" + response.data.google + ")" });
                        }
                    } else {
                        this.setState({ errorCoordinates: "Er ging iets fout bij het verversen van de coördinaten. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorCoordinates: "Er ging iets fout bij het verversen van de coördinaten. Probeer het later opnieuw." });
            });
    }

    onHandleStatusChanged = this.handleStatusChanged.bind(this);
    handleStatusChanged(status) {
        this.handleCloseSetStatusModal();
        this.setInstallationProps({ status });
        this.clearInstallationWarnings();
        this.getInstallationComments(this.props.installationId);
    }

    onHandleInvoicingStatusChanged = this.handleInvoicingStatusChanged.bind(this);
    handleInvoicingStatusChanged(invoicingStatus) {
        this.handleCloseSetInvoicingStatusModal();
        this.setInstallationProps({ invoicingStatus });
        this.clearInstallationWarnings();
        this.getInstallationComments(this.props.installationId);
    }

    onHandleCloseSetStatusModal = this.handleCloseSetStatusModal.bind(this);
    handleCloseSetStatusModal() {
        this.setState({ showSetStatusModal: false });
    }

    onHandleCloseSetInvoicingStatusModal = this.handleCloseSetInvoicingStatusModal.bind(this);
    handleCloseSetInvoicingStatusModal() {
        this.setState({ showSetInvoicingStatusModal: false });
    }

    getAddress() {
        const installation = this.props.installation;
        return installation.street + " " + installation.houseNumber + ", " + installation.city;
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            );
        }
        const installation = this.props.installation;
        return (
            <React.Fragment>
                { installation !== null && (
                    <React.Fragment>
                        <InstallationDetailSetStatusModal
                            show={ this.state.showSetStatusModal }
                            installation={ installation }
                            handleClose={ this.onHandleCloseSetStatusModal }
                            handleStatusChanged={ this.onHandleStatusChanged }
                            handleCommentAdded={ this.onCommentAdded }
                        />
                        <InstallationDetailSetInvoicingStatusModal
                            show={ this.state.showSetInvoicingStatusModal }
                            installation={ installation }
                            handleClose={ this.onHandleCloseSetInvoicingStatusModal }
                            handleStatusChanged={ this.onHandleInvoicingStatusChanged }
                            handleCommentAdded={ this.onCommentAdded }
                        />
                        <AppleMapsModal
                            show={ this.state.showAppleMapsModal }
                            latitude={ installation.latitude }
                            longitude={ installation.longitude }
                            title={ installation.lead.name }
                            emptyState="Er zijn geen coördinaten beschikbaar voor deze installatie."
                            handleClose={ () => this.setState({ showAppleMapsModal: false }) }
                            refreshCoordinates={ this.refreshCoordinates.bind(this) }
                            refreshCoordinatesError={ this.state.errorCoordinates }
                        />
                        <InstallationSendVATRefundToken
                            show={ this.state.showSendVATRefundTokenModal }
                            installation={ this.props.installation }
                            handleClose={ () => this.setState({ showSendVATRefundTokenModal: false }) }
                            refreshInstallation={ this.props.refreshInstallation }
                        />
                    </React.Fragment>
                )}

                { installation !== null && installation.warnings.map((warning, index) => (
                    <Alert variant="warning" key={ index } className="d-print-none">{ warning }</Alert>
                ))}

                <DetailOverviewQRCode
                    content={ installation ? `installation=${installation.id}` : null }
                    caption="Scan deze code in de Sales app om deze installatie direct te openen op je telefoon."
                />

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Naam">
                                { installation ? (
                                    <ClickCopy text={ installation.lead.name }>
                                        { installation.lead.name }
                                    </ClickCopy>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Status">
                                { installation ? (
                                    <React.Fragment>
                                        <TagPill color={installation.status.color}>
                                            {installation.status.name}
                                        </TagPill>
                                        <Button variant="link" size="sm" onClick={ () => this.setState({ showSetStatusModal: true }) }>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={175}/>
                                )}
                            </DetailOverviewRow>
                            { installation && installation.status.installationStarted && (
                                <DetailOverviewRow title="Facturatie status">
                                    <TagPill color={installation.invoicingStatus.color}>
                                        {installation.invoicingStatus.name}
                                    </TagPill>
                                    <Button variant="link" size="sm" onClick={ () => this.setState({ showSetInvoicingStatusModal: true }) }>
                                        <i className="fas fa-pen"/>
                                    </Button>
                                </DetailOverviewRow>
                            )}
                            <DetailOverviewRow title="Adres">
                                { installation ? (
                                    <React.Fragment>
                                        <ClickCopy text={ this.getAddress() }>
                                            { this.getAddress() }
                                        </ClickCopy>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Google Maps</Tooltip>
                                        }>
                                            <a href={ "https://google.com/maps?q=" + encodeURIComponent(this.getAddress()) + "&t=k" }
                                               className="btn btn-link btn-sm" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-map"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Google Earth</Tooltip>
                                        }>
                                            <a href={ "https://earth.google.com/web/search/" + encodeURIComponent(this.getAddress()) }
                                               className="btn btn-link btn-sm" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-globe-europe"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Adres openen in Apple Maps</Tooltip>
                                        }>
                                            <Button onClick={ () => this.setState({ showAppleMapsModal: true }) } variant="link" size="sm">
                                                <i className="fab fa-apple"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Postcode">
                                { installation ? (
                                    <ClickCopy text={ installation.postalCode }>
                                        { installation.postalCode }
                                    </ClickCopy>
                                ) : <Skeleton width={75}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Telefoonnummer">
                                { installation ? (
                                    <a href={"dial:" + installation.lead.phoneNumber}>
                                        {installation.lead.phoneNumber}
                                    </a>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Email">
                                { installation ? (
                                    <a href={"mailto:" + installation.lead.email}>{installation.lead.email}</a>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="BTW Terugvraag">
                                { installation ? (
                                    installation.hasVATRefund ? (
                                        <i className="fas fa-check text-success"/>
                                    ) : (
                                        <i className="fas fa-times"/>
                                    )
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Datum">
                                { installation ? (
                                    <DateFormatter date={installation.creationDate}/>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Aangemaakt door">
                                { installation ? (
                                    installation.author ? installation.author.name : (
                                        <i className="text-muted">Automatisch</i>
                                    )
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Locatie">
                                { installation ? (
                                    <React.Fragment>
                                        { installation.latitude && installation.longitude ? (
                                            <React.Fragment>
                                                { installation.latitude + ", " + installation.longitude }
                                            </React.Fragment>
                                        ) : (
                                            <i className="text-muted">Geen locatie beschikbaar</i>
                                        )}
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Coördinaten verversen</Tooltip>
                                        }>
                                            <button className="btn btn-link btn-sm ml-2 py-0" onClick={ () => this.refreshCoordinates() }>
                                                <i className="fas fa-sync"/>
                                            </button>
                                        </OverlayTrigger>
                                        { installation.latitude && installation.longitude && (
                                            <React.Fragment>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="address">Coördinaten openen in Google Maps</Tooltip>
                                                }>
                                                    <a href={ "https://google.com/maps?q=" + installation.latitude + "," + installation.longitude + "&t=k" }
                                                       className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                        <i className="fas fa-map"/>
                                                    </a>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="address">Coördinaten openen in Google Earth</Tooltip>
                                                }>
                                                    <a href={ "https://earth.google.com/web/search/" + installation.latitude + "," + installation.longitude }
                                                       className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                        <i className="fas fa-globe-europe"/>
                                                    </a>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="address">Coördinaten openen in Apple Maps</Tooltip>
                                                }>
                                                    <Button onClick={ () => this.setState({ showAppleMapsModal: true }) } variant="link" size="sm" className="py-0">
                                                        <i className="fab fa-apple"/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </React.Fragment>
                                        )}
                                        { this.state.errorCoordinates !== null && (
                                            <Alert variant="danger" className="mb-0">{ this.state.errorCoordinates }</Alert>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Materiaal">
                                { this.state.products ? (
                                    this.state.products.length > 0 ? this.state.products.map((product) => (
                                        <div className="d-flex w-100" key={ product.id }>
                                            <div className="mr-2">
                                                <ProductAvailabilityIcon availability={ product.availability }/>
                                            </div>
                                            <div className="flex-grow-1">
                                                { product.installationAmount }x { product.brand.name } { product.name }
                                            </div>
                                        </div>
                                    )) : (
                                        <i className="text-muted">Geen materiaal</i>
                                    )
                                ) : (
                                    <Skeleton count={3}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Beschrijving">
                                { installation ? (
                                    installation.description && installation.description.length > 0 ? (
                                        <ReactMarkdownBreaks message={ installation.description }/>
                                    ) : (
                                        <i className="text-muted">Leeg</i>
                                    )
                                ) : (
                                    <Skeleton count={3}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow>
                                { installation ? (
                                    <React.Fragment>
                                        <Link to={ `/lead/${installation.lead.id}` } className="btn btn-secondary btn-sm">
                                            Lead openen
                                        </Link>
                                        { installation.hasVATRefund && (
                                            <Button variant="secondary" size="sm" className="ml-2" onClick={ () => this.setState({ showSendVATRefundTokenModal: true }) }>
                                                BTW Terugvraag link versturen
                                            </Button>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Skeleton height={30} width={100}/>
                                        <span className="ml-2">
                                            <Skeleton height={30} width={205}/>
                                        </span>
                                    </React.Fragment>
                                )}
                            </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>
                <DetailOverviewComments
                    comments={ this.state.comments }
                    error={ this.state.errorComments }
                    errorNewComment={ this.state.errorNewComment }
                    newCommentLoading={ this.state.newCommentLoading }
                    onSubmit={ this.addComment.bind(this) }
                    commentUpdated={ this.commentUpdated.bind(this) }
                    commentDeleted={ this.commentDeleted.bind(this) }
                    emptyState="Er staan nog geen notities onder deze installatie."
                />
            </React.Fragment>
        )
    }
}

export default InstallationDetailOverview;
