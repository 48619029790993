import React, {
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import ProductQuickActionSetAssignedBuilding from "../../products/product-detail/components/ProductQuickActionSetAssignedBuilding";
import ProductQuickActionSetAmount from "../../products/product-detail/components/ProductQuickActionSetAmount";
import NumberFormatter from "../../../components/formatters/NumberFormatter";

function InventoryProductRow({ product, onSave, showProductHistory }) {
    const [error, setError] = useState(null);
    return (
        <React.Fragment>
            { error && (
                <tr>
                    <td colSpan={ 10 }>
                        <Alert variant="danger" className="mb-0">{ error }</Alert>
                    </td>
                </tr>
            )}
            <tr>
                <th className="va-middle text-center">
                    { product.id }
                </th>
                <td className="va-middle">
                    { product.brand.name }
                </td>
                <td className="va-middle">
                    { product.name }
                </td>
                <td className="va-middle">
                    <TagPill color={ product.type.color }>
                        { product.type.name }
                    </TagPill>
                </td>
                <td className="va-middle">
                    <ProductQuickActionSetAssignedBuilding
                        product={ product }
                        setProduct={ onSave }
                        onError={ setError }
                    />
                </td>
                <td className="va-middle text-right">
                    <ProductQuickActionSetAmount
                        product={ product }
                        setProduct={ onSave }
                        onError={ setError }
                    />
                </td>
                <td className="va-middle text-right">
                    <NumberFormatter number={ product.amountExpected }/>
                </td>
                <td className="va-middle text-right">
                    { product.amountReserved > 0 && (
                        <OverlayTrigger overlay={
                            <Tooltip id="edit">Overzicht van reserveringen voor dit product</Tooltip>
                        }>
                            <Link to={ `/products/${product.brand.id}/product/${product.id}/reserved` } className="mr-1">
                                <i className="fas fa-info-circle mr-2"/>
                            </Link>
                        </OverlayTrigger>
                    )}
                    <NumberFormatter number={ product.amountReserved }/>
                </td>
                <td className="va-middle text-right text-muted">
                    { product.amount + product.amountExpected > 0 && product.amountReserved > 0 ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ Math.ceil(((product.amount + product.amountExpected) / product.amountReserved) * 100) }
                            />%
                        </React.Fragment>
                    ) : "0%" }
                </td>
                <td className="va-middle text-right">
                    { product.lastUpdated === null ? "Nooit" : ( <DateFormatter date={ product.lastUpdated }/> ) }
                </td>
                <td className="va-middle d-print-none">
                    <div className="btn-group float-right">
                        <OverlayTrigger overlay={
                            <Tooltip id="detail">Product openen</Tooltip>
                        }>
                            <Link
                                to={ `/products/${product.brand.id}/product/${product.id}` }
                                className="btn btn-primary btn-sm btn-nowrap"
                            >
                                <i className="fas fa-info fa-fw"/>
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                            <Tooltip id="history">Geschiedenis</Tooltip>
                        }>
                            <Button variant="secondary" size="sm" className="btn-nowrap" onClick={ showProductHistory }>
                                <i className="fas fa-history fa-fw"/>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default React.memo(InventoryProductRow);
