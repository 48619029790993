import React from "react";
import TagPill from "../tagPill";
import DateFormatter from "../formatters/DateFormatter";
import DeviceTypeIcon from "./DeviceTypeIcon";

function getEventIcon(event) {
    switch(event.type) {
        case "changedStatus":
            return (
                <i className="fas fa-tag"/>
            )
        case "changedInvoicingStatus":
            return (
                <i className="fas fa-receipt"/>
            )
        case "changedAssignedUser":
            return (
                <i className="fas fa-user"/>
            )
        case "changedCompletedByUser":
            return (
                <i className="fas fa-user-check"/>
            )
        case "changedServiceSetting":
            return (
                <i className="fas fa-handshake-alt"/>
            )
        case "changedPriority":
            return (
                <i className="fas fa-thunderstorm"/>
            )
        default:
            return (
                <i className="fas fa-question-circle"/>
            )
    }
}

function getEventBody(event) {
    switch(event.type) {
        case "changedStatus":
            return (
                <React.Fragment>
                    <span>heeft de status veranderd van </span>
                    <TagPill color={ event.data.from.color }>{ event.data.from.name }</TagPill>
                    <span> naar </span>
                    <TagPill color={ event.data.to.color }>{ event.data.to.name }</TagPill>
                </React.Fragment>
            )
        case "changedInvoicingStatus":
            return (
                <React.Fragment>
                    <span>heeft de facturatie status veranderd van </span>
                    <TagPill color={ event.data.from.color }>{ event.data.from.name }</TagPill>
                    <span> naar </span>
                    <TagPill color={ event.data.to.color }>{ event.data.to.name }</TagPill>
                </React.Fragment>
            )
        case "changedAssignedUser":
            return (
                <React.Fragment>
                    <span>heeft de toegewezen werknemer veranderd van </span>
                    <b>{ event.data.from === null ? "niemand" : event.data.from.name }</b>
                    <span> naar </span>
                    <b>{ event.data.to === null ? "niemand" : event.data.to.name }</b>
                </React.Fragment>
            )
        case "changedCompletedByUser":
            return (
                <React.Fragment>
                    <span>heeft de verkocht door werknemer veranderd van </span>
                    <b>{ event.data.from === null ? "niemand" : event.data.from.name }</b>
                    <span> naar </span>
                    <b>{ event.data.to === null ? "niemand" : event.data.to.name }</b>
                </React.Fragment>
            )
        case "changedServiceSetting":
            return (
                <React.Fragment>
                    <span>heeft de service instelling veranderd van </span>
                    { event.data.from === null ? (
                        <TagPill color="#595959">Onbepaald</TagPill>
                    ) : (
                        <TagPill color={ event.data.from.color }>{ event.data.from.name }</TagPill>
                    )}
                    <span> naar </span>
                    { event.data.to === null ? (
                        <TagPill color="#595959">Onbepaald</TagPill>
                    ) : (
                        <TagPill color={ event.data.to.color }>{ event.data.to.name }</TagPill>
                    )}
                </React.Fragment>
            )
        case "changedPriority":
            return (
                <React.Fragment>
                    <span>heeft de prioriteit veranderd van </span>
                    { event.data.from === null ? (
                        <TagPill color="#595959">Onbepaald</TagPill>
                    ) : (
                        <TagPill color={ event.data.from.color }>{ event.data.from.name }</TagPill>
                    )}
                    <span> naar </span>
                    { event.data.to === null ? (
                        <TagPill color="#595959">Onbepaald</TagPill>
                    ) : (
                        <TagPill color={ event.data.to.color }>{ event.data.to.name }</TagPill>
                    )}
                </React.Fragment>
            )
        default:
            return (
                <p>Unimplemented event type: { event.type }</p>
            )
    }
}

function CompactEvent({ event }) {
    const icon = getEventIcon(event);
    const body = getEventBody(event);
    return (
        <div className="card-comment-container">
            <div className="event">
                <div className="event-icon">
                    { icon }
                </div>
                <div className="event-body">
                    <div className="d-none d-lg-block">
                        <span className="float-right ml-4"><DateFormatter date={ event.date }/></span>

                        <DeviceTypeIcon deviceType={ event.deviceType } className="mr-2"/>
                        <b>{ event.user ? event.user.name : "Automatisch" } </b>

                        { body }
                    </div>
                    <div className="d-lg-none">
                        <DeviceTypeIcon deviceType={ event.deviceType } className="mr-2"/>
                        <b>{ event.user ? event.user.name : "Automatisch" } </b>

                        { body }

                        <p className="text-muted mt-1 mb-0">
                            <DateFormatter date={ event.date }/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OriginalEvent({ event }) {
    const body = getEventBody(event);
    return (
        <div className="card card-comment">
            <div className="card-body">
                <span className="float-right ml-4"><DateFormatter date={ event.date }/></span>

                <DeviceTypeIcon deviceType={ event.deviceType } className="mr-2"/>
                <b>{ event.user.name } </b>

                { body }
            </div>
        </div>
    )
}

function Event({ event, style }) {
    if(style === "compact") {
        return (
            <CompactEvent event={ event }/>
        )
    }
    return (
        <OriginalEvent event={ event }/>
    )
}

export default React.memo(Event);
