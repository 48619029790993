import React, {
    useContext
} from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import IntranetNavbar from "./components/layout/IntranetNavbar";
import IntranetSidebar from "./components/layout/sidebar/IntranetSidebar";
import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import useTracking from "./hooks/useTracking";
import ScrollToTop from "./hooks/ScrollToTop";
import IntranetManager from "./IntranetManager";
import IntranetPageContainer from "./components/layout/IntranetPageContainer";

import RemindersSwitch from "./pages/reminders/RemindersSwitch";
import Dashboard from "./pages/dashboard/Dashboard";
import Images from "./pages/images/Images";
import LeadsSwitch from "./pages/leads/Leads";
import LeadDetail from "./pages/leads/detail/LeadDetail";
import InstallationsSwitch from "./pages/installations/InstallationsPage";
import InstallationDetail from "./pages/installations/detail/InstallationDetail";
import PlanningPage from "./pages/planning/PlanningPage";
import InvoicingSwitch from "./pages/invoicing/Invoicing";
import VATRefundSwitch from "./pages/vat-refund/VATRefundSwitch";
import VATRefundDetail from "./pages/vat-refund/detail/VATRefundDetail";
import OutagesSwitch from "./pages/outages/Outages";
import OutageDetail from "./pages/outages/detail/OutageDetail";
import RestockOrdersPage from "./pages/restock-orders/RestockOrdersPage";
import RestockOrderDetail from "./pages/restock-orders/detail/RestockOrderDetail";
import InventorySwitch from "./pages/inventory/InventorySwitch";
import ProductsSwitch from "./pages/products/ProductsSwitch";
import ProductsForecast from "./pages/products/ProductsForecast";
import AbsencePage from "./pages/absence/AbsencePage";
import Statistics from "./pages/statistics/Statistics";
import HelpSwitch from "./pages/help/HelpSwitch";
import UserDetail from "./pages/users/detail/UserDetail";
import RegisteredDevicesMap from "./pages/registered-devices/RegisteredDevicesMap";
import Page404 from "./pages/Page404";

function IntranetRouter() {
    const authenticatedUser = useContext(AuthenticatedUserContext);
    useTracking(["default"]);
    return (
        <IntranetManager>
            <IntranetNavbar/>
            <IntranetSidebar/>

            <ScrollToTop/>

            <IntranetPageContainer>
                <Switch>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/reminders" component={RemindersSwitch} />
                    <Route path={["/images/:page", "/images"]} component={Images} />
                    <Route path="/leads" component={LeadsSwitch} />
                    <Route path="/lead/:id" component={LeadDetail} />
                    <Route path="/installations" component={InstallationsSwitch} />
                    <Route path="/installation/:id" component={InstallationDetail} />
                    <Route path="/planning" component={PlanningPage} />
                    { authenticatedUser.user.invoicing && (
                        <Route path="/invoicing" component={InvoicingSwitch} />
                    )}
                    <Route path="/vat-refunds" component={VATRefundSwitch} />
                    <Route path="/vat-refund/:id" component={VATRefundDetail} />
                    <Route path="/outages" component={OutagesSwitch} />
                    <Route path="/outage/:id" component={OutageDetail} />
                    <Route path="/orders" component={RestockOrdersPage} />
                    <Route path="/order/:id" component={RestockOrderDetail} />
                    <Route path="/inventory" component={InventorySwitch} />
                    <Route path="/products" component={ProductsSwitch} />
                    <Route path="/products-forecast" component={ProductsForecast} />
                    <Route path="/absence" component={AbsencePage} />
                    <Route path={["/statistics/:startDate/:endDate", "/statistics"]} component={Statistics} />
                    <Route path="/help" component={HelpSwitch} />
                    <Route path="/user/:userId" component={UserDetail} />
                    <Route path="/registered-devices" component={RegisteredDevicesMap}/>

                    <Route path="/" exact>
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                                state: { from: "/" }
                            }}
                        />
                    </Route>
                    <Route path="/" component={Page404} />
                </Switch>
            </IntranetPageContainer>
        </IntranetManager>
    )
}

export default IntranetRouter;
