import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import InstallationDetailAddProductModal from "./modal/InstallationDetailAddProductModal";
import InstallationDetailRemoveProductModal from "./modal/InstallationDetailRemoveProductModal";
import InstallationProductCard from "./components/InstallationProductCard";
import InstallationTakenProductCard from "./components/InstallationTakenProductCard";
import InstallationDetailTakeProductModal from "./modal/InstallationDetailTakeProductModal";
import InstallationDetailUndoTakeProductModal from "./modal/InstallationDetailUndoTakeProductModal";
import ParentSummary from "../../../components/ParentSummary";
import InstallationDetailReplaceProductModal from "./modal/InstallationDetailReplaceProductModal";

class InstallationDetailProducts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reservedProducts: null,
            takenProducts: null,
            error: null,

            showAddProductsModal: false,

            showRemoveProductsModal: false,
            removeProductModalProduct: null,

            showReplaceProductModal: false,
            replaceProductModalProduct: null,

            showTakeProductsModal: false,
            takeProductModalProduct: null,

            showUndoTakenProductsModal: false,
            undoTakenProductModalProduct: null
        };
        this.setProductsState = this.setProductsState.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.showAddProductModal = this.showAddProductModal.bind(this);
    }

    componentDidMount() {
        this.getProducts();
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event) {
        if(event.keyCode === 78) { // N key
            if(this.showAddProductModal()) {
                event.preventDefault();
            }
        }
    }

    showAddProductModal() {
        if(this.state.error) {
            return false;
        }
        if(!this.state.reservedProducts || !this.state.takenProducts) {
            return false;
        }
        if(this.state.showAddProductsModal) {
            return false;
        }
        this.setState({ showAddProductsModal: true });
        return true;
    }

    getProducts() {
        this.setState({ error: null });
        axios.post("/getInstallationProducts", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setProductsState(response.data.reserved, response.data.taken, true);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setProductsState(reserved, taken, initialLoad = false) {
        if(!initialLoad) {
            let refresh = false;
            if(this.state.reservedProducts && this.state.takenProducts) {
                if(this.state.reservedProducts.length === 0 && Object.keys(this.state.takenProducts).length === 0) {
                    this.props.refreshInstallation();
                    refresh = true;
                }
            }
            if(!refresh && reserved.length === 0 && taken.length === 0) {
                this.props.refreshInstallation();
            }
        }

        this.setState({
            reservedProducts: reserved
        });

        let groupedProducts = {};
        for(const takenProduct of taken) {
            if(!(takenProduct.id in groupedProducts)) {
                groupedProducts[takenProduct.id] = [];
            }
            groupedProducts[takenProduct.id].push(takenProduct);
        }
        this.setState({
            takenProducts: groupedProducts
        });
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            );
        }
        if(this.state.reservedProducts === null || this.state.takenProducts === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 80 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }

        const takenProducts = Object.values(this.state.takenProducts);

        return (
            <React.Fragment>
                <InstallationDetailAddProductModal
                    show={ this.state.showAddProductsModal }
                    existingProducts={ this.state.reservedProducts }
                    handleClose={ () => this.setState({ showAddProductsModal: false }) }
                    onSave={ this.setProductsState }
                    installationId={ this.props.installationId }
                />
                <InstallationDetailRemoveProductModal
                    show={ this.state.showRemoveProductsModal }
                    product={ this.state.removeProductModalProduct }
                    installationId={ this.props.installationId }
                    handleClose={ () => this.setState({ showRemoveProductsModal: false }) }
                    onRemove={ this.setProductsState }
                />
                <InstallationDetailReplaceProductModal
                    show={ this.state.showReplaceProductModal }
                    handleClose={ () => this.setState({ showReplaceProductModal: false }) }
                    installationProduct={ this.state.replaceProductModalProduct }
                    existingProducts={ this.state.reservedProducts }
                    onSave={ this.setProductsState }
                />
                <InstallationDetailTakeProductModal
                    show={ this.state.showTakeProductsModal }
                    product={ this.state.takeProductModalProduct }
                    installationId={ this.props.installationId }
                    handleClose={ () => this.setState({ showTakeProductsModal: false }) }
                    onTaken={ this.setProductsState }
                />
                <InstallationDetailUndoTakeProductModal
                    show={ this.state.showUndoTakenProductsModal }
                    takenProduct={ this.state.undoTakenProductModalProduct }
                    handleClose={ () => this.setState({ showUndoTakenProductsModal: false }) }
                    onUndo={ this.setProductsState }
                />

                <ParentSummary
                    installation={ this.props.installation }
                />

                { this.state.reservedProducts.length === 0 && Object.keys(this.state.takenProducts).length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-warehouse"/></h1>
                        <h3>Geen materiaal</h3>
                        <p>Er is nog geen materiaal toegevoegd aan deze installatie.</p>
                        <p className="d-none d-lg-block">
                            <b>Tip!</b> Gebruik
                            <kbd className="mx-2">
                                N
                            </kbd>
                            om snel een product toe te voegen.
                        </p>
                        <Button
                            variant="primary"
                            className="mb-3"
                            size="sm"
                            onClick={ this.showAddProductModal }
                        >
                            <i className="fas fa-plus mr-2"/>
                            Materiaal toevoegen
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ this.showAddProductModal }
                        >
                            <i className="fas fa-plus mr-2"/>
                            Materiaal toevoegen
                        </Button>
                        { this.state.reservedProducts.length > 0 && (
                            <h4 className="mt-3">Gereserveerd materiaal</h4>
                        )}
                        { this.state.reservedProducts.map((product) => (
                            <InstallationProductCard
                                key={ product.id }
                                product={ product }
                                installationId={ this.props.installationId }
                                onSave={ this.setProductsState }
                                onRemove={ () => this.setState({ showRemoveProductsModal: true, removeProductModalProduct: product }) }
                                onReplace={ () => this.setState({ showReplaceProductModal: true, replaceProductModalProduct: product }) }
                                openTakeProductModal={ () => this.setState({ showTakeProductsModal: true, takeProductModalProduct: product }) }
                            />
                        ))}
                        { takenProducts.length > 0 && (
                            <h4 className="mt-3">Afgenomen materiaal</h4>
                        )}
                        { takenProducts.map((products) => (
                            <InstallationTakenProductCard
                                key={ products[0].id }
                                product={ products[0] }
                                products={ products }
                                installationId={ this.props.installationId }
                                onUndo={ (takenProduct) => this.setState({ showUndoTakenProductsModal: true, undoTakenProductModalProduct: takenProduct }) }
                            />
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default InstallationDetailProducts;
