import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import Loading from "../Loading";
import Comment from "./Comment";

function CommentRevisionsModal(props) {
    return (
        <Modal size="lg" show={ props.show } onHide={ props.handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Geschiedenis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props.comment !== null ? (
                    <React.Fragment>
                        { props.comment.revisions.map((revision, index) => (
                            <Comment
                                key={ index }
                                comment={{
                                    message: revision.message,
                                    user: revision.author,
                                    date: revision.date
                                }}
                                noButtons
                                className={ revision.type === "original" ? "border-primary" : revision.type === "current" ? "border-success mb-0" : "" }
                                customCardHeader={
                                    <React.Fragment>
                                        { revision.type === "original" ? (
                                            <div className="card-header text-primary">
                                                <b>Originele versie</b>
                                            </div>
                                        ) : revision.type === "current" && (
                                            <div className="card-header text-success">
                                                <b>Huidige versie</b>
                                            </div>
                                        )}
                                    </React.Fragment>
                                }
                            />
                        )) }
                    </React.Fragment>
                ) : (
                    <Loading/>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ props.handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(CommentRevisionsModal);
