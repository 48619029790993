import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function getFontAwesomeIcon(availability) {
    switch(availability) {
        case "taken":
        case "fully":
            return (
                <i className="fas fa-check fa-fw text-success"/>
            )
        case "partially":
            return (
                <i className="fas fa-tilde fa-fw text-warning"/>
            )
        case "none":
            return (
                <i className="fas fa-times fa-fw text-danger"/>
            )
        default:
            return (
                <i className="fas fa-question fa-fw text-muted"/>
            )
    }
}

function getTooltipText(availability) {
    switch(availability) {
        case "taken": return "Product reeds afgenomen";
        case "fully": return "Product volledig beschikbaar";
        case "partially": return "Product aantal deels beschikbaar";
        case "none": return "Product niet beschikbaar";
        default: return "Onbekende beschikbaarheid"
    }
}

function ProductAvailabilityIcon({ availability }) {
    const icon = getFontAwesomeIcon(availability);
    const text = getTooltipText(availability);

    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="availability">{ text }</Tooltip>
        }>
            { icon }
        </OverlayTrigger>
    )
}

export default React.memo(ProductAvailabilityIcon);
