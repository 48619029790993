import React from "react";
import moment from "moment";

import ChartCard from "../components/ChartCard";

import InstalledSolarPanelsCountPerWeekChart from "../charts/InstalledSolarPanelsCountPerWeekChart";
import InstalledSolarPanelsCountPerMonthChart from "../charts/InstalledSolarPanelsCountPerMonthChart";
import InstalledSolarPanelsCountPerYearChart from "../charts/InstalledSolarPanelsCountPerYearChart";

function InstallationsStatistics() {
    return (
        <React.Fragment>
            <ChartCard title="Aantal geïnstalleerde zonnepanelen per week">
                <InstalledSolarPanelsCountPerWeekChart
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment() }
                />
            </ChartCard>
            <ChartCard title="Aantal geïnstalleerde zonnepanelen per maand">
                <InstalledSolarPanelsCountPerMonthChart
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment() }
                />
            </ChartCard>
            <ChartCard title="Aantal geïnstalleerde zonnepanelen per year">
                <InstalledSolarPanelsCountPerYearChart
                    startDate={ moment("2021-01-01") }
                    endDate={ moment() }
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(InstallationsStatistics);
