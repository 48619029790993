import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/ChartLoading";
import ChartNoData from "../components/ChartNoData";

function InstalledSolarPanelsCountPerYearChart({ startDate, endDate }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const startDateFormat = startDate.format("YYYY-MM-DD");
        const endDateFormat = endDate.format("YYYY-MM-DD");
        axios.post("/charts/installedSolarPanelsCountPerYearChart.php", { startDate: startDateFormat, endDate: endDateFormat })
            .then((response) => {
                if(response.data.valid) {
                    setData(response.data.data);
                } else {
                    setError("Er ging iets fout. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout.");
            })
    }, [startDate, endDate]);

    const primaryAxis = useMemo(
        () => ({
            getValue: (datum) => datum.key
        }), []
    );
    const secondaryAxes = useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                stacked: true
            },
        ], []
    );

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
            }}
        />
    )
}

export default React.memo(InstalledSolarPanelsCountPerYearChart);
