import React from "react";
import axios from "axios";
import {
    Alert,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import InventoryHistoryModal from "./InventoryHistoryModal";
import Loading from "../../components/Loading";
import InventoryProductRow from "./components/InventoryProductRow";
import InventoryTabBar from "./components/InventoryTabBar";
import OverviewSortTableHeader from "../../components/overview/OverviewSortTableHeader";

class InventoryPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.searchQuery = this.getSearchQuery();
        this.state = {
            products: null,
            error: false,

            lastSortKey: null,
            search: this.searchQuery,

            historyModalProduct: null
        };
        this.onProductChange = this.onProductChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSort = this.onSort.bind(this);
    }

    componentDidMount() {
        this.getProducts();
    }

    getLocalStoragePrefix() {
        return "inventory"
    }

    getSearchQuery() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SearchQuery") || "";
    }

    setSearchQuery(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SearchQuery", value);
    }

    getSortKey() {
        const key = localStorage.getItem(this.getLocalStoragePrefix() + "SortKey");
        return key === null || key === "null" ? null : key;
    }

    setSortKey(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortKey", value);
    }

    getSortAscending() {
        return (localStorage.getItem(this.getLocalStoragePrefix() + "SortAscending") || "1") === "1";
    }

    setSortAscending(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortAscending", value ? "1" : "0");
    }

    onSearch(event) {
        if(!event || !event.target) {
            return;
        }

        const currentSearchQuery = event.target.value;
        this.searchQuery = currentSearchQuery;
        this.setState({ search: currentSearchQuery });
        this.setSearchQuery(currentSearchQuery);

        if(currentSearchQuery.length === 0) {
            this.getProducts();
        } else {
            setTimeout(() => {
                if(this.searchQuery === currentSearchQuery) {
                    this.getProducts();
                }
            }, 500);
        }
    }

    onReset() {
        this.searchQuery = "";
        this.setState({ search: "", lastSortKey: null });
        this.setSearchQuery("");
        this.setSortKey(null);
        this.getProducts();
    }

    getProducts() {
        this.setState({ products: null });
        const searchQuery = this.searchQuery.trim();
        axios.post("/getProducts", { searchQuery: searchQuery.length > 0 ? searchQuery : undefined })
            .then((response) => {
                if(searchQuery !== this.searchQuery.trim()) {
                    return;
                }
                if(response.data.valid) {
                    this.setState({ products: this.sortProductData(response.data.data, this.getSortKey(), this.getSortAscending()) });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    onSort(key = null, ascending = null) {
        if(typeof ascending !== 'boolean') {
            ascending = this.state.lastSortKey !== key;
        }
        this.setState((state) => {
            return {
                products: this.sortProductData(state.products, key, ascending)
            }
        });
    }

    sortProductData(products, key, ascending) {
        this.setState({ lastSortKey: !ascending ? "p" + (key ?? "default") : key ?? "default" });
        this.setSortKey(key);
        this.setSortAscending(ascending);
        if(!products || key == null) {
            return products;
        }
        return products.sort(((a, b) => {
            let aValue = a[key] && (typeof a[key] === "object" ? ("name" in a ? a[key]['name'] : a[key]['id']) : a[key]);
            aValue = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
            let bValue = b[key] && (typeof b[key] === "object" ? ("name" in b ? b[key]['name'] : b[key]['id']) : b[key]);
            bValue = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

            if(aValue === null && bValue !== null) {
                return ascending ? 1 : -1;
            } else if(aValue !== null && bValue === null) {
                return ascending ? -1 : 1;
            } else if(aValue < bValue) {
                return ascending ? 1 : -1;
            } else if(aValue > bValue) {
                return ascending ? -1 : 1;
            } else if(aValue === bValue) {
                return 0;
            } else if(a.id < b.id) {
                return ascending? 1 : -1;
            } else {
                return ascending? -1 : 1;
            }
        }))
    }

    onProductChange(product) {
        this.setState((prevState) => {
            const products = prevState.products.map((prevProduct) => {
                return prevProduct.id === product.id ? product : prevProduct;
            });
            return { products };
        })
    }

    showInventoryHistory(product) {
        this.setState({ showHistoryModal: true, historyModalProduct: product });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Voorraad"/>

                <Title preTitle="Overzicht" noBottom>
                    Actuele voorraad
                </Title>

                <InventoryTabBar noBottom={ !this.state.error }>
                    <div className="ml-auto align-self-center">
                        <div className="input-group float-right my-2" style={{maxWidth: 300}}>
                            <input type="search" className="search form-control"
                                   placeholder="Zoeken" value={ this.state.search } onChange={ this.onSearch } />
                            <div className="input-group-append">
                                <OverlayTrigger overlay={
                                    <Tooltip id="reset">Reset</Tooltip>
                                }>
                                    <button className="btn btn-secondary" onClick={ this.onReset }>
                                        <i className="fas fa-undo"/>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </InventoryTabBar>

                <InventoryHistoryModal
                    show={ this.state.showHistoryModal }
                    product={ this.state.historyModalProduct }
                    handleClose={ () => this.setState({ showHistoryModal: false })}
                />

                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : (
                    <React.Fragment>
                        <table className="table table-sm">

                            <thead className="thead-light">
                            <tr className="tr-sticky">
                                <OverviewSortTableHeader
                                    title="#"
                                    sortKey="id"
                                    className="text-center"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                    minWidth="60px"
                                />
                                <OverviewSortTableHeader
                                    title="Merk"
                                    sortKey="brand"
                                    className="text-left"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <OverviewSortTableHeader
                                    title="Product"
                                    sortKey="name"
                                    className="text-left"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <OverviewSortTableHeader
                                    title="Type"
                                    sortKey="type"
                                    className="text-left"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <OverviewSortTableHeader
                                    title="Locatie"
                                    sortKey="assignedBuilding"
                                    className="text-left"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <OverviewSortTableHeader
                                    title="Totaal op voorraad"
                                    sortKey="amount"
                                    className="text-right"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                    maxWidth="120px"
                                />
                                <OverviewSortTableHeader
                                    title="Besteld"
                                    sortKey="amountExpected"
                                    className="text-right"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <OverviewSortTableHeader
                                    title="Gereserveerd"
                                    sortKey="amountReserved"
                                    className="text-right"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <th
                                    style={{ whiteSpace: "nowrap" }}
                                    className="text-right"
                                >
                                    Inkoop %
                                </th>
                                <OverviewSortTableHeader
                                    title="Laatst bijgewerkt"
                                    sortKey="lastUpdated"
                                    className="text-right"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                                <th className="d-print-none"/>
                            </tr>
                            </thead>

                            <tbody>
                            { this.state.products === null ? (
                                <tr>
                                   <td colSpan={ 9 }>
                                       <Loading/>
                                   </td>
                                </tr>
                            ) : this.state.products.length === 0 ? (
                                <tr>
                                    <td colSpan={ 10 } className="text-center">
                                        <h1><i className="fas fa-inventory"/></h1>
                                        <h3>Geen producten</h3>
                                        <p>Er zijn nog geen producten toegevoegd.</p>
                                    </td>
                                </tr>
                            ) : this.state.products.map((product) => (
                                <InventoryProductRow
                                    key={ product.id }
                                    product={ product }
                                    onSave={ this.onProductChange }
                                    showProductHistory={ () => this.showInventoryHistory(product) }
                                />
                            )) }
                            </tbody>

                        </table>

                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default InventoryPage;
